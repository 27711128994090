import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CodeService, StorageService, BlocklyService, Example, userInfo } from '../shared';
import * as tfvis from '@tensorflow/tfjs-vis';
import { DatasetDialogComponent } from '../dataset-dialog/dataset-dialog.component';
import { LocaleService } from '../shared/locale/locale.service';
import axios from 'axios';
import qs from 'qs'
import { TranslateService } from '@ngx-translate/core';
import { AngularSplitModule } from 'angular-split';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  examples = [
    {
      title: 'Tensorflow',
      children: [
        {
          title: '2D Data',
          type: Example.Tensor2D,
        },
        {
          title: 'Iris Flower',
          type: Example.TensorIris,
        },
        {
          title: 'Digit Recognition',
          type: Example.TensorMnist,
        },
        {
          title: 'kNN Classifier',
          type: Example.TensorKnn,
        },
      ]
    },
    {
      title: 'Face Recognition',
      children: [
        {
          title: 'via Image',
          type: Example.FaceImage,
        },
        {
          title: 'via Webcam',
          type: Example.FaceVideo,
        },
        {
          title: 'via Image (with Post Request)',
          type: Example.FacePost,
        },
      ]
    },
    {
      title: 'Hand Tracking',
      type: Example.Handtrack,
    },
    {
      title: 'Object Detection',
      type: Example.ObjectDetection
    },
    {
      title: 'Pose Detection',
      type: Example.PoseDetection
    },
    {
      title: 'Teachable Machine',
      type: Example.Teachable,
    },
  ];

  link = [
    {
      title: 'Graphical Mode',
      children: [
        {
          title: 'IoT module',
          href: 'x.cocorobo.cn',
        },
        {
          title: 'A.I module',
          href: 'x.cocorobo.cn'
        },
        {
          title: 'Python Blockly',
          href: "python-blockly.cocorobo.cn"
        },
        {
          title: 'AI blockly',
          href: 'ai-blockly.cocorobo.cn'
        }
      ]
    },
    {
      title: 'Code Mode',
      children: [
        {
          title: 'IoT module',
          href: 'x.cocorobo.cn/python/'
        },
        {
          title: 'A.I module',
          href: 'x.cocorobo.cn/python/'
        }, {
          title: 'Python Blockly',
          href: 'python-blockly.cocorobo.cn'
        }
      ]
    }
  ];

  login_modal_show = true;
  isCN = false;
  exampleTypes = 0;

  UserInfo = {};
  userState = false;

  USER = {
    api_key: null,
    evnets: null
  }

  selectEvent = null;
  isRefresh = false;
  isCopy = false;

  base_url = window.location.hostname.indexOf("cocorobo.cn") != -1 ? '//api.cocorobo.cn/' : '//api.cocorobo.hk/'

  locales = [
    {
      title: 'English',
      locale: 'en'
    },
    {
      title: '繁體中文',
      locale: 'zh-hant'
    },
    {
      title: '简体中文',
      locale: 'zh-hans'
    },
  ];
  currentLocale: string;

  constructor(
    private codeService: CodeService,
    private storageService: StorageService,
    private blocklyService: BlocklyService,
    private localeService: LocaleService,
    private dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    this.localeService.locale$.subscribe(locale => {
      this.currentLocale = locale;
    })
  }

  // url 取值
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }


  ngOnInit() {
    this.getUser()
    this.loginCn();
    // const val = location.search.match(new RegExp('[?&]lang=([^&]+)'));
    // let language = val ? decodeURIComponent(val[1].replace(/\+/g, '%20')) : '';
    // if (language) {
    //   localStorage.setItem('locale', language)
    //   this.localeService.updateLocale(language);
    // } else {
    //   this.localeService.updateLocale("en");
    // }

    if (this.getQueryString('code') && this.getQueryString('state')) {
      console.log(this.getQueryString('code'), this.getQueryString('state'))
      axios.get('http://oapi.dingtalk.com/sso/gettoken?corpid=dingf113ce959247d417ee0f45d8e4f7c288&corpsecret=09wTCSVdQMXDcqxGImreaAvvCfIF0BxfZsp393l_s9_XR5JZxcw3ha3zEm1LPq8e').then(res => {
        console.log(res)
      })
      
      // axios.post(`https://oapi.dingtalk.com/sns/getuserinfo_bycode?signature=xxxxxx&timestamp=${new Date().getTime()}&accessKey=dingsdbwmwnnnywdnk8e`)
    } else {
      
    }
  }

  loginCn() {
    if (window.location.hostname.indexOf("cocorobo.cn") != -1) {
      this.isCN = true;
    }
    else {
      this.isCN = false;
    }
  }

  onImport(files: File[]) {
    let input_value = document.getElementById('input_value') as HTMLInputElement
    input_value.value = files[0].name.slice(0, files[0].name.length - 4)
    this.storageService.import(files[0]);
  }

  onExport() {
    this.storageService.export();
  }

  onupload(files) {
    console.log(files[0])
    var fileReader = new FileReader();
    fileReader.onload = function (ev) {
      try {
        var data = ev.target.result,
          workbook = XLSX.read(data, {
            type: 'binary'
          }), // 以二进制流方式读取得到整份excel表格对象
          persons = []; // 存储获取到的数据
      } catch (e) {
        console.log('文件类型不正确');
        return;
      }

      // 表格的表格范围，可用于判断表头是否数量是否正确
      var fromTo = '';
      // 遍历每张表读取
      for (var sheet in workbook.Sheets) {
        if (workbook.Sheets.hasOwnProperty(sheet)) {
          fromTo = workbook.Sheets[sheet]['!ref'];
          console.log(fromTo, workbook.Sheets[sheet]);
          persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          // break; // 如果只取第一张表，就取消注释这行
        }
      }
      console.log(persons);
      (<any>window).irisList = persons.map(x => {
        for (const i in x) {
          if (Object.hasOwnProperty.call(x, i)) {
            const element = x[i];
            // console.log(i,element)
            return element;
          }
        }
      })
    };

    fileReader.readAsBinaryString(files[0]);

  }

  onChartClick() {
    tfvis.visor().open();
  }

  onExampleClick(exampleType: Example) {
    this.codeService.changeExample(exampleType);
    this.exampleTypes = exampleType
  }

  onLocaleClick(locale: string) {
    this.localeService.updateLocale(locale);
    // 不刷新切换 url 后缀
    history.pushState(null, `${location.search}`, `/?lang=${locale}`)
    if (localStorage.getItem('BLOCKLY_XML')) {
      this.codeService.changeExample(this.exampleTypes);
    }
  }

  onDatasetClick() {
    this.dialog.open(DatasetDialogComponent, {
      width: '70%',
      disableClose: true,
    });
  }

  // 获取登录后的用户信息
  getUser() {
    var that = this;
    window.addEventListener('message', function (e) {
      console.log('e.data', e.data)
      let data = e.data;
      if (typeof data === "object" && data.id === "loginVerify" && data.status === "logged") {
        that.login_modal_show = false;
        that.UserInfo = data.data;
        that.userState = true;
        that.USER.api_key = data.data.apiKey;
        that.getCoCloudEvent();
      }
    })
  }

  onLogin() {
    // document.getElementById('login_modals').classList.remove('hidden');
    // this.login_modal_show = false;
    window.location.href = "https://oapi.dingtalk.com/connect/qrconnect?appid=dingsdbwmwnnnywdnk8e&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" + window.encodeURI("http://jingshi.cocorobo.cn");
  }

  isLogin() {
    document.getElementById('login_modals').classList.remove('hidden');
    this.login_modal_show = false;
  }

  modal_hide(data, $event) {
    if ($event.path[0].id === "login_modals") {
      document.getElementById('login_modals').classList.add('hidden');
    }
  }

  Go_cloud() {
    // window.location.href = "https://cocorobo.cn/cloud"
    if (window.location.hostname.indexOf("cocorobo.cn") != -1) {
      window.open('https://cocorobo.cn/cloud', '_blank');
    }
    else {
      window.open('https://cocorobo.hk/cloud', '_blank');
    }
  }

  modal_hideX() {
    document.getElementById('login_modals').classList.add('hidden');
  }

  login_out() {
    var that = this;
    axios.get(`${that.base_url}api/logout`, { 'withCredentials': true }).then(() => {
      console.log('login out')
      this.login_modal_show = true;
      this.userState = false;
      that.UserInfo = {};
    }).catch(err => {
      console.log('登出失败')
    })
  }

  getCoCloudEvent() {
    var that = this;
    that.isRefresh = true;
    axios.get(`${that.base_url}iot/data/apikey/${that.USER.api_key}/event/`, { 'withCredentials': true }).then((data) => {
      that.USER.evnets = data.data;
      if (that.USER.evnets.length != 0) {
        document.getElementById("cloud_events").innerHTML = "";
        that.selectEvent = that.USER.evnets[0].eventAPIKey;
        for (let i = 0; i < that.USER.evnets.length; i++) {
          document.getElementById("cloud_events").appendChild(
            that.create_opts(that.USER.evnets[i].name, that.USER.evnets[i].eventAPIKey, false)
          );
        }
        that.isRefresh = false;
        // document.getElementById("cloud_events").select();
        setTimeout(function () { document.getElementById("api-key").innerText = that.selectEvent }, 100);
      }
    });
  }

  updateProject() {
    this.getCoCloudEvent();
  }

  changeProject($event) {
    document.getElementById("api-key").innerText = "";
    if ($event != null) {
      document.getElementById("api-key").innerText = $event;
    }
  }

  copyToClipboard(oElement, value) {
    var that = this;
    var aux = document.createElement('input');
    if (oElement) {
      var content = oElement.innerHTML || oElement.value;
    }
    var _content = value || content;
    aux.setAttribute('value', _content);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('Copy');
    document.body.removeChild(aux);
    that.isCopy = true;
    setTimeout(function () { that.isCopy = false; }, 2000);
  }

  create_opts(text, value, disabled = false) {
    const option = document.createElement('option');
    option.textContent = text;
    option.value = value;
    disabled ? option.disabled = true : null;
    return option;
  }

  onLinkClick(link: string, title: string) {
    let lang = window.localStorage.getItem('locale') || 'en'
    let links = link
    if (window.location.hostname.indexOf("beta.") != -1) {
      links = `beta.${link}`
      if (window.location.hostname.indexOf("cocorobo.cn") == -1) {
        links = links.replace('.cn', '.hk')
      }
    } else {
      if (window.location.hostname.indexOf("cocorobo.cn") == -1) {
        links = links.replace('.cn', '.hk')
      }
    }
    if (title === 'A.I module') {
      window.open(`https://${links}?lang=${lang}&name=1`, '_blank');
    } else if (title === 'IoT module') {
      window.open(`https://${links}?lang=${lang}&name=0`, '_blank');
    } else {
      window.open(`https://${links}?lang=${lang}`, '_blank');
    }
  }
}
