<mat-toolbar color="primary" class="mat-elevation-z2">
  <span>AI Blockly</span>
  <div>
    <!-- <button mat-button (click)="onDatasetClick()">{{'Dataset' | translate}}</button> -->
    <button mat-button [matMenuTriggerFor]="menu">
      <fa-icon icon="exchange-alt" rotate="90" size="lg" class="mr-1"></fa-icon>
      {{'Storage' | translate}}
    </button>
    <input id="input_value" matInput placeholder="{{'Project' | translate}}" type="text" class="item_input">
    <button style="color:#2c4fcd" mat-raised-button (click)="onExport()">{{'Saves' | translate}}</button>
    <mat-menu #menu="matMenu">
      <input #fileInput type="file" (change)="onImport($event.target.files)" style="display: none;" />
      <button mat-menu-item (click)="fileInput.click()">{{'Import' | translate}}</button>
      <button mat-menu-item (click)="onExport()">{{'Export' | translate}}</button>
    </mat-menu>
  </div>
  <div fxFlex></div>
  <input id="input_upload" placeholder="{{'Project' | translate}}" (change)="onupload($event.target.files)" type="file" class="item_input" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
  <button mat-button [matMenuTriggerFor]="exampleMenu">{{'Examples' | translate}}</button>
  <mat-menu #exampleMenu="matMenu">
    <ng-container *ngFor="let example of examples">
      <ng-container *ngIf="example.children">
        <button mat-menu-item [matMenuTriggerFor]="subMenu">{{example.title | translate}}</button>
        <mat-menu #subMenu="matMenu">
          <button mat-menu-item *ngFor="let subExample of example.children"
            (click)="onExampleClick(subExample.type)">{{subExample.title | translate}}</button>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="!example.children">
        <button mat-menu-item (click)="onExampleClick(example.type)">{{example.title | translate}}</button>
      </ng-container>
    </ng-container>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="aiBlocklyMenu">
    <span class="ai_blockly">AI blockly<i class="down"></i></span>
  </button>
  <mat-menu #aiBlocklyMenu="matMenu">
    <ng-container *ngFor="let example of link">
      <ng-container *ngIf="example.children">
        <button mat-menu-item [matMenuTriggerFor]="subMenu">{{example.title | translate}}</button>
        <mat-menu #subMenu="matMenu">
          <button mat-menu-item *ngFor="let subExample of example.children"
            (click)="onLinkClick(subExample.href,subExample.title)">{{subExample.title | translate}}</button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </mat-menu>
  <button mat-button (click)="onChartClick()">
    <fa-icon icon="chart-bar" size="lg" class="mr-1"></fa-icon>
    {{'Visualization Tool' | translate}}
  </button>
  <div>
    <button mat-button [matMenuTriggerFor]="localeMenu">
      <fa-icon icon="globe" size="lg" class="mr-1"></fa-icon>
      {{'Language' | translate}}
    </button>
    <mat-menu #localeMenu="matMenu">
      <ng-container *ngFor="let locale of locales">
        <button mat-menu-item (click)="onLocaleClick(locale.locale)">
          {{locale.title}}
          <fa-icon icon="check" *ngIf="locale.locale == currentLocale"></fa-icon>
        </button>
      </ng-container>
    </mat-menu>
    <div class="login_div" (click)="onLogin()">
      <img class="nav_login" src="../../assets/icons/user.png" alt="user">
      <button *ngIf="!userState" class="login">
        {{'Login' | translate}}
      </button>
      <span *ngIf="userState" class="login_on">
        {{ UserInfo.alias }}
      </span>
    </div>
    <div class="login_modals hidden" id="login_modals" (click)="modal_hide('data',$event)">
      <div id="self_defined_element" class="self-defined-classname"></div>
      <div style="display: none;" id="login_modal" class="login_modal">
        <iframe *ngIf="login_modal_show && isCN" scrolling="no" class="login_in" id="login_in"
          src="//cocorobo.cn/login/" frameborder="0"></iframe>
        <iframe *ngIf="login_modal_show && !isCN" scrolling="no" class="login_in" id="login_in"
          src="//cocorobo.hk/login/" frameborder="0"></iframe>
          
        <div *ngIf="!login_modal_show" class="after_login" id="after_login" style="padding: 24px 20px;color: black;">
          <p class="user_name">
            <span>{{ UserInfo.alias }}</span>
            <span (click)="modal_hideX()">X</span>
          </p>
          <div class="p_class">
            <span>{{'Email' | translate}}</span>
            <span>{{ UserInfo.username }}</span>
          </div>
          <div class="p_class">
            <span>{{'School' | translate}}</span>
            <span>{{ UserInfo.defaultSchool }}</span>
          </div>
          <div class="p_class">
            <span>{{'Cloud' | translate}}</span>
            <span style="margin-top: -13px;float:right;transform:translateY(35%);">
              <i id="update_Coevent" class="material-icons" (click)="updateProject()" [matTooltip]="'Click to refresh the event list' | translate">autorenew</i>
            </span>
            <span *ngIf = "isRefresh">
              <p class="get_event" style="margin: 5px 0;color: #2196F3;">{{ 'Getting a list of the latest events' |
                translate }}</p>
            </span>
          </div>
          <!-- <select matNativeControl required class="select">
            <option value="volvo">Volvo</option>
            <option value="saab">Saab</option>
            <option value="mercedes">Mercedes</option>
            <option value="audi">Audi</option>
          </select> -->
          <select style="outline:0; width: 100%; margin: 6px auto 8px auto; border: 1px solid rgba(0,0,0,.2);"
            class="select" id="cloud_events" (ngModelChange)="changeProject($event)" [(ngModel)]="selectEvent">
            <option value=null disabled></option>
          </select>
          <div class="p_class">
            <span>API Key</span>
            <span *ngIf = "isCopy" style="color: #2196F3;margin-left: 15px;">
              {{ 'Copied Successfully!' | translate }}
            </span>
            <a id="apikey-copy" data-clipboard-action="copy" data-clipboard-target="#api-key"
              (click)="copyToClipboard(null,selectEvent)" [matTooltip]="'Copy Apikey to clipboard' | translate">
              <i class="material-icons tiny">content_copy</i>
            </a>
          </div>
          <!-- <select matNativeControl required class="select">
            <option value="volvo">Volvo</option>
            <option value="saab">Saab</option>
            <option value="mercedes">Mercedes</option>
            <option value="audi">Audi</option>
          </select> -->
          <textarea id="api-key" class="api-key" style="padding: 6px 6px; margin-top: 4px;" readonly></textarea>
          <div class="footer_button">
            <!-- <a class="go_cloud" href="//cocorobo.cn/cloud" target="_blank">
              {{'GoCloud' | translate}}
            </a> -->
            <button mat-raised-button style="background-color:#2196F3;color: #fff;" (click)="Go_cloud()">
              {{'GoCloud' | translate}}
            </button>
            <button mat-raised-button style="background-color:#2196F3;color: #fff;" (click)="login_out()">
              {{'LoginOut' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>